import axios from "axios";
import { ID, Invoice } from "models";
import { APIData } from "./types";

export const getInvoice = (id: ID): Promise<APIData<Invoice>> => {
  return axios
    .get(`/api/v1/invoice/${id}/`, {
      headers: {
        // "X-CSRFToken": Cookies.get("csrftoken"),
        // "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      },
      timeout: 10000
    })
    .then((res) => {
      return res.data as Promise<APIData<Invoice>>;
    });
};
