import axios from "axios";
import { Contract } from "models";
import { TContractFilter } from "types/TContractFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsContract extends TSearchParamsPropsNoFilter {
  filter?: TContractFilter;
}

export const getContractList = (props?: IPropsContract): Promise<APIListData<Contract>> => {
  return axios
    .get(
      props === undefined ? `/api/v1/contract/` : `/api/v1/contract/?${getSearchParams(props)}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        timeout: 10000
      }
    )
    .then((res) => {
      return res.data as Promise<APIListData<Contract>>;
    });
};
