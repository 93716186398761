import axios from "axios";
import Cookies from "js-cookie";
import {ID, Statistic} from "models";
import { APIData } from "./types";

export const getStatistics = (id?: ID): Promise<APIData<Statistic>> => {
    return axios
        .get(`/api/v2/statistics/${id}/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken"),
                "Content-Type": "application/json;charset=utf-8"
            },
            timeout: 10000
        })
        .then((res) => {
            return res.data as Promise<APIData<Statistic>>;
        });
};
