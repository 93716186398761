import axios from "axios";
import { ID, Participant } from "models";
import { APIData } from "./types";

export const getParticipant = (id: ID): Promise<APIData<Participant>> => {
  return axios
    .get(`/api/v1/participant/${id}/`, {
      headers: {
        // "X-CSRFToken": Cookies.get("csrftoken"),
        // "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      },
      timeout: 10000
    })
    .then((res) => {
      return res.data as Promise<APIData<Participant>>;
    });
};
