import { getContractList } from "api/contract_list";
import { getParticipantList } from "api/participant_list";
import { uploadInvoicelessStatistic } from "api/upload_file";
import { parse } from "date-fns";
import {
  BaseSyntheticEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect, useMemo,
  useState
} from "react";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useMutation } from "react-query";
import { Form } from "reactstrap";
import { CheckBoxField, DatePickerField, DropZoneField, PredictiveSearchField } from "ui";
import {TFormInitialParams} from "../types";

export type TStatisticsForm = {
  client?: number | string;
  contract?: number | string;
  contractor?: number | string;
  end_date?: string;
  self_ad?: boolean;
  start_date?: string;
  statistics_file?: string;
  participants_has_ord?: boolean;
};

type Props = {
  control: Control<TStatisticsForm, any>;
  errors: FieldErrors<TStatisticsForm>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  buttons: ReactNode;
  watch: UseFormWatch<TStatisticsForm>;
  setValue: UseFormSetValue<TStatisticsForm>;
  setError: UseFormSetError<TStatisticsForm>;
  clearErrors: UseFormClearErrors<TStatisticsForm>;
  isLoading?: boolean;
  initialParams?: TFormInitialParams
  setAmountFromAllocationsFile: Dispatch<SetStateAction<any>>;
  isDraft?: boolean;
  statisticsOldFile?: string;
};

export const StatisticsForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  isLoading,
  initialParams,
  setAmountFromAllocationsFile,
  isDraft,
  setValue,
  setError,
  clearErrors,
  statisticsOldFile
}: Props) => {
  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(initialParams ? initialParams.client.name : undefined);
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(initialParams ? initialParams.contractor.name : undefined);
  const [searchContractsValue, setSearchContractsValue] = useState<string | undefined>(
    initialParams ? initialParams.contractNumber : undefined
  );

  const clientField = watch('client')
  const contractorField = watch('contractor')
  const contractField = watch('contract')
  const statisticsFile = watch('statistics_file')
  const isSelfAd = watch("self_ad");

  const client = useMemo(() => {
    return searchClientParticipantsValue || clientField || ''
  }, [clientField, searchClientParticipantsValue])

  const contractor = useMemo(() => {
    return searchContractorParticipantsValue || contractorField || ''
  }, [contractorField, searchContractorParticipantsValue]);

  const contract = useMemo(() => {
    return searchContractsValue || contractField || ''
  }, [contractField, searchContractsValue])

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useMutation({
    mutationFn: () => getParticipantList(
        {search: client ? String(client) : ''}
    ),
  });

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useMutation({
    mutationFn: () =>
      getParticipantList(
          {
            search: contractor ? String(contractor) : ''
          }
      ),
  });

  const {
    mutate: contractsRequest,
    isLoading: contractsLoading,
    data: contractsResponse
  } = useMutation({
    mutationFn: () =>
      getContractList({
        search: contract ? String(contract) : '',
        filter: {
          client: clientField,
          contractor: contractorField,
          contract_type: isSelfAd ? [4] : undefined,
        }
      }),
    onSuccess: ({data}) => {
      if (isSelfAd) {
        setValue("contract", data.results[0].id)
        return;
      }
    }
  });

  const hasOrdAccount = useMemo(() => {
    const selectedClient = clientField
      ? clientParticipantsResponse?.data.results.find((p) => p.id === watch("client"))
      : undefined;
    const selectedContractor = contractorField
      ? contractorParticipantsResponse?.data.results.find((p) => p.id === watch("contractor"))
      : undefined;

    return !!selectedClient?.extra_fields?.ord_accounts.length || !!selectedContractor?.extra_fields?.ord_accounts.length
  }, [clientField, contractorField, contractorParticipantsResponse, clientParticipantsResponse])

  useEffect(() => {
    if ((isSelfAd && clientField) || (contractorField && clientField)) {
      contractsRequest();
    } else {
      setValue("contract", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientField, contractorField]);

  const validateClientContractor = () => {
    if (hasOrdAccount) {
      clearErrors("client");
      clearErrors("contractor");
    } else {
      setError("client", {
        type: "custom",
        message:
          "Передача информации в ЕРИР невозможна из-за отсутствия привязанного аккаунта ОРД у обеих сторон договора."
      });
      setError("contractor", {
        type: "custom",
        message:
          "Передача информации в ЕРИР невозможна из-за отсутствия привязанного аккаунта ОРД у обеих сторон договора."
      });
      return "Передача информации в ЕРИР невозможна из-за отсутствия привязанного аккаунта ОРД у обеих сторон договора.";
    }
  };

  const validateDateInterval = () => {
    const start_date = watch("start_date");
    const end_date = watch("end_date");
    const sDate = start_date ? parse(start_date, "yyyy-MM-dd", new Date()) : new Date(0);
    const eDate = end_date ? parse(end_date, "yyyy-MM-dd", new Date()) : new Date(0);
    if (sDate.getMonth() === eDate.getMonth() && sDate.getFullYear() === eDate.getFullYear()) {
      clearErrors("start_date");
      clearErrors("end_date");
    } else {
      setError("start_date", {
        type: "custom",
        message: "Период охватывает более одного календарного месяца."
      });
      setError("end_date", {
        type: "custom",
        message: "Период охватывает более одного календарного месяца."
      });
      return "Период охватывает более одного календарного месяца.";
    }
  };

  useEffect(() => {
    if (searchClientParticipantsValue) setValue("client", initialParams?.client.id)
    if (searchContractorParticipantsValue) setValue("contractor", initialParams?.contractor.id)
    if (searchContractsValue) setValue("contract", initialParams?.contractNumber)
  }, [searchClientParticipantsValue, searchContractorParticipantsValue, searchContractsValue]);

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className="row align-items-center">
        <div className="col-sm-9">
          <CheckBoxField
            name="self_ad"
            title="Креативы к договору саморекламы"
            control={control}
            isChecked={isSelfAd}
            extraAction={() => {
              setValue("client", undefined);
              setValue("contractor", undefined);
              setValue("contract", undefined);
            }}
          />
        </div>
      </div>
      {isSelfAd ? (
        <div className="row align-items-center">
          <div className="col-sm-9">
            <PredictiveSearchField
              name="client"
              title="Контрагент саморекламы"
              optionKeyLabels={["Название", "ИНН", "hidden", "hidden"]}
              optionKey={["name", "inn", "status", "extra_fields.ord_accounts.length"]}
              inputValue={searchClientParticipantsValue}
              setInputValue={setSearchClientParticipantsValue}
              control={control}
              errors={errors}
              isLoading={clientParticipantsLoading}
              request={clientParticipantsRequest}
              response={clientParticipantsResponse}
              disabled={isLoading || contractsLoading}
              clearable
              isRequired={!isDraft}
              isStared
              optionDisableCondition={[{ status: 2 }, { "extra_fields.ord_accounts.length": 0 }]}
              infoText='Нельзя выбрать контрагентов, статус которых "ЧЕРНОВИК" и/или у которых нет ОРД аккаунтов'
            />
          </div>
        </div>
      ) : (
        <>
          <div className="row align-items-center">
            <div className="col-sm-9">
              <PredictiveSearchField
                name="client"
                title="Заказчик"
                optionKeyLabels={["Название", "ИНН", "hidden"]}
                optionKey={["name", "inn", "status"]}
                inputValue={searchClientParticipantsValue}
                setInputValue={setSearchClientParticipantsValue}
                control={control}
                errors={errors}
                isLoading={clientParticipantsLoading}
                request={clientParticipantsRequest}
                response={clientParticipantsResponse}
                disabled={isLoading}
                clearable
                isRequired={!isDraft}
                isStared
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                validate={validateClientContractor}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-9">
              <PredictiveSearchField
                name="contractor"
                title="Исполнитель"
                optionKeyLabels={["Название", "ИНН", "hidden"]}
                optionKey={["name", "inn", "status"]}
                inputValue={searchContractorParticipantsValue}
                setInputValue={setSearchContractorParticipantsValue}
                control={control}
                errors={errors}
                isLoading={contractorParticipantsLoading}
                request={contractorParticipantsRequest}
                response={contractorParticipantsResponse}
                disabled={isLoading}
                clearable
                isRequired={!isDraft}
                isStared
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                validate={validateClientContractor}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-9">
              <PredictiveSearchField
                name="contract"
                title="Выберите договор к которому относится акт"
                optionKey={["contract_number", "client", "contractor", "status"]}
                optionKeyLabels={["Номер контракта", "client", "contractor", "hidden"]}
                inputValue={searchContractsValue}
                setInputValue={setSearchContractsValue}
                control={control}
                isLoading={contractsLoading}
                request={contractsRequest}
                response={contractsResponse}
                disabled={isLoading || !clientField || !contractorField}
                clearable
                errorText="Необходимо заполнить. Для заполнения выберете заказчика и исполнителя."
                errors={errors}
                isRequired={!isDraft}
                isStared
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
              />
            </div>
          </div>
        </>
      )}
      <div className="row align-items-center">
        <div className="col-sm-6">
          <DatePickerField
            name="start_date"
            title="Начало периода загрузки статистики"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
            validate={validateDateInterval}
            infoText={"Период загрузки статистики должен быть указан в пределах одного календарного месяца"}
          />
        </div>
        <div className="col-sm-6">
          <DatePickerField
            name="end_date"
            title="Окончание периода загрузки статистики"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
            validate={validateDateInterval}
          />
        </div>
      </div>
      <DropZoneField
        name="statistics_file"
        title="Файл с данными по статистике без актов"
        exampleLink="/static/core/import_templates/invoiceless_statistics.xlsx"
        control={control}
        errors={errors}
        fileTypes={[".xlsx", ".xls", ".csv"]}
        maxFiles={1}
        uploadFunction={uploadInvoicelessStatistic}
        isRequired={!isDraft}
        isStared
        responsePrintField="file_path"
        setAdditionalData={{
          fn: setAmountFromAllocationsFile,
          field: "amount"
        }}
        oldFile={statisticsOldFile}
      />
      {buttons}
    </Form>
  );
};
