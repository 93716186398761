import ErrorComponent from "Components/ErrorComponent";
import ListingLayout from "Layout/ListingLayout";
import Cookies from "js-cookie";
import { ID } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { TContractFilter } from "types/TContractFilter";
import createNotification from "ui/Notification";
import getLastPageNumber from "utils/getLastPageNumber";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import useIsOpenControl from "utils/useIsOpenControl";
import { ContractFilter } from "./components/filter";
import { ContractListingActions } from "./components/listingActions";
import { ContractTable } from "./components/table";
import { defaultExportLink, defaultSearchParams, orderVariants } from "./constants";
import useContractsSerchParams from "./hooks/useContractsSerchParams";
import useLoadContracts from "./hooks/useLoadContracts";
import { useFilterCount } from "../../utils/useFilterCount";

export const ContractList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const searchParams = useContractsSerchParams({ searchProperties, dispatch });
  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const { isLoading, count, contracts, error, refetch } = useLoadContracts({ searchParams });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeContact", page_size.toString());
    const lastPageNumber = getLastPageNumber(count, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onFilterClick = (filter: TContractFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const filterCount = useFilterCount(searchParams.filter)
  const onExport = () => {
    const emptyFilter = filterCount === 0
    if (emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    if (!emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все отфильтрованные данные", 3000);
    setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds, ...searchParams.filter } })}`);
  };
  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onResetFilter={onResetFilter}
      onOrderSelect={onOrderSelect}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/contract/add"
      isDataLoading={isLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={count}
      actionsDropdownMenu={
        <ContractListingActions onExport={onExport} exportLink={exportLink} refetch={refetch} />
      }
      table={
        <ContractTable
          contracts={contracts}
          refetch={refetch}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={contracts.filter(({ id }) => !!id).map(({ id }) => id as number)}
        />
      }
      orderVariants={orderVariants}
      filter={
        <ContractFilter
          filter={searchParams.filter}
          filterClear={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
      searchPlaceholder="Введите ID элемента, название ООО, ИНН, комментарий или номер договора"
    />
  );
};
