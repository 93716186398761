import {ID, Statistic} from "../models";
import axios from "axios";
import Cookies from "js-cookie";

type StatisticUpdateBody = Pick<Statistic, 'contract' | 'start_date' | 'end_date' | 'status' | 'statistics_file'>

export const editStatistics = ({ id, statistic }: { id: ID; statistic: StatisticUpdateBody }) => {
    return axios
        .put(`/api/v2/statistics/${id}/`, statistic, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken"),
                "Content-Type": "application/json;charset=utf-8"
            }
        })
        .then((res) => {
            return res;
        });
};
