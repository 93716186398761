import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsRequired } from "types/TSearchParams";
import { OrderingVariant } from "ui/SortSelector";

export const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "title",
    title: "Наименование ↑"
  },
  {
    order: "-title",
    title: "Наименование ↓"
  },
  {
    order: "marker",
    title: "Токен ↑"
  },
  {
    order: "-marker",
    title: "Токен ↓"
  }
];

export const defaultSearchParams: TSearchParamsRequired<TCreativeFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: orderVariants[1].order,
  filter: {
    marker: undefined,
    ord_account: [],
    combined_status: [],
    initial_contract__client: [],
    start_date: undefined,
    end_date: undefined,
    advertisement_form: [],
    advertisement_type: [],
    contract: []
  }
};

export const defaultExportLink = "/api/v2/creative/export/xls";
