import ListingLayout from "Layout/ListingLayout";
import Cookies from "js-cookie";
import {ID, Statistic} from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TStatisticsFilter } from "types/TStatisticsFilter";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import useIsOpenControl from "utils/useIsOpenControl";
import { StatisticsFilter } from "./components/filter";
import { StatisticListingActions } from "./components/statisticsActions";
import { StatisticsTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";
import useStatisticsSearchParams from "./hooks/useStatisticsSearchParams";
import useLoadStatisticsList from "./hooks/useLoadStatistics";
import createNotification from "ui/Notification";
import { useFilterCount } from "utils/useFilterCount";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  }
];

export const StatisticsList = () => {
  const navigate = useNavigate();

  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();

  const searchParams = useStatisticsSearchParams({searchProperties, dispatch})

  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();


  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const {data: statisticsInfoList, isLoading: statisticsLoading, refetch: statisticsRefetch, error: statisticsError} = useLoadStatisticsList({ searchParams })

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeStatistics", page_size.toString());
    const lastPageNumber = getLastPageNumber(statisticsInfoList?.data?.count || 0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onFilterClick = (filter: TStatisticsFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const filterCount = useFilterCount(searchParams.filter)
  const onExport = () => {
    const emptyFilter = filterCount === 0
    if (emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    if (!emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все отфильтрованные данные", 3000);
    setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds, ...searchParams.filter } })}`);
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (statisticsError) {
    return <div>Error</div>;
  }

  const statistics: Statistic[] = statisticsInfoList?.data.results || [];

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onOrderSelect={onOrderSelect}
      onPageSizeSelect={onPageSizeSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/statistics/add"
      isDataLoading={statisticsLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentOrder={searchParams.ordering}
      currentFilter={searchParams.filter}
      count={statisticsInfoList?.data?.count}
      orderVariants={orderVariants}
      searchPlaceholder="Введите ID элемента, название ООО, ИНН или номер договора"
      actionsDropdownMenu={
        <StatisticListingActions
          onExport={onExport}
          exportLink={exportLink}
          refetch={statisticsRefetch}
        />
      }
      table={
        <StatisticsTable
          statistics={statistics}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={statistics.filter(({ id }) => !!id).map(({ id }) => id as number)}
          refetch={statisticsRefetch}
        />
      }
      filter={
        <StatisticsFilter
          filter={searchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
    />
  );
};
