import ErrorComponent from "Components/ErrorComponent";
import Cookies from "js-cookie";
import ListingLayout from "Layout/ListingLayout";
import { ID } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { TPlatformFilter } from "types/TPlatformFilter";
import createNotification from "ui/Notification";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import useIsOpenControl from "utils/useIsOpenControl";
import { PlatformFilter } from "./components/filter";
import { PlatformListingActions } from "./components/listingActions";
import { PlatformTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";
import useLoadPlatforms from "./hooks/useLoadPlatforms";
import usePlatformsSerchParams from "./hooks/usePlatformsSerchParams";
import { useFilterCount } from "../../utils/useFilterCount";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "platform_name",
    title: "Название ↑"
  },
  {
    order: "-platform_name",
    title: "Название ↓"
  }
];

export const PlatformList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const searchParams = usePlatformsSerchParams({ searchProperties, dispatch });
  const { isLoading, error, refetch, count, platforms } = useLoadPlatforms({ searchParams });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page_size: searchParams.page_size,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizePlatform", page_size.toString());
    const lastPageNumber = getLastPageNumber(count, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onFilterClick = (filter: TPlatformFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const filterCount = useFilterCount(searchParams.filter)
  const onExport = () => {
    const emptyFilter = filterCount === 0
    if (emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    if (!emptyFilter && !exportIds.length) createNotification("warning", "Ничего не выбрано, в файл попадут все отфильтрованные данные", 3000);
    setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds, ...searchParams.filter } })}`);
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onOrderSelect={onOrderSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/platform/add"
      isDataLoading={isLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={count}
      actionsDropdownMenu={
        <PlatformListingActions onExport={onExport} exportLink={exportLink} refetch={refetch} />
      }
      orderVariants={orderVariants}
      table={
        <PlatformTable
          platforms={platforms}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={platforms.filter(({ id }) => !!id).map(({ id }) => id as number)}
          refetch={refetch}
        />
      }
      filter={
        <PlatformFilter
          filter={searchParams.filter}
          clearFilter={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
      searchPlaceholder="Введите ID элемента или URL"
    />
  );
};
