import { getContractList } from "api/contract_list";
import { getParticipantList } from "api/participant_list";
import { uploadAllocations, uploadCreativeStatistic } from "api/upload_file";
import { ID, InvoiceContract, InvoiceParticipant } from "models";
import {
  BaseSyntheticEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useForm } from "react-hook-form";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { FaPlus } from "react-icons/fa";
import { useMutation } from "react-query";
import { Button, Form } from "reactstrap";
import { TContractFilter } from "types/TContractFilter";
import {
  CheckBoxField,
  DatePickerField,
  DropZoneField,
  InputField,
  PredictiveSearchField
} from "ui";
import ConnectStatToInvoiceModal from "./ConnectStatToInvoiceModal";
import {validateAmount} from "../utils";


type TContractForm = {
  client?: ID;
  contractor?: ID;
};

export type InitialContractParams = {
  client?: InvoiceParticipant;
  contractor?: InvoiceParticipant;
  contractNumber?: InvoiceContract["number"];
};

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  buttons: ReactNode;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  isLoading?: boolean;
  initialParams?: InitialContractParams;
  setAmountFromAllocationsFile: Dispatch<SetStateAction<any>>;
  isDraft: boolean;
  allocationsFileOld?: string;
  statisticsFileOld?: string;
};

export const InvoiceForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  isLoading,
  initialParams,
  // setAmountFromAllocationsFile,
  isDraft,
  setValue,
  allocationsFileOld,
  statisticsFileOld
}: Props) => {
  const noInvoiceNumber = (watch("noNumber") as boolean | undefined) || false;

  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(undefined);
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(undefined);
  const [searchContractsValue, setSearchContractsValue] = useState<string | undefined>(
    initialParams?.contractNumber || ""
  );
  const [isConnectStatToInvoiceModalOpen, setIsConnectStatToInvoiceModalOpen] = useState(false);
  const toggleIsConnectStatToInvoiceModal = () =>
    setIsConnectStatToInvoiceModalOpen((prev) => !prev);

  const {
    control: contractControl,
    reset: contractReset,
    watch: contractWatch,
  } = useForm<TContractForm>({
    defaultValues: {} as TContractForm
  });

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useMutation({
    mutationFn: () => {
      const clientId = contractWatch("client");
      return getParticipantList(
        searchClientParticipantsValue
          ? { search: searchClientParticipantsValue }
          : { filter: clientId ? { id: [clientId] } : {} }
      );
    }
  });

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useMutation({
    mutationFn: () => {
      const contractorId = contractWatch("contractor");
      return getParticipantList(
        searchContractorParticipantsValue
          ? { search: searchContractorParticipantsValue }
          : { filter: contractorId ? { id: [contractorId] } : {} }
      );
    }
  });

  const {
    mutate: contractsRequest,
    isLoading: contractsLoading,
    data: contractsResponse
  } = useMutation({
    mutationFn: () => {
      const clientId = contractWatch("client");
      const contractorId = contractWatch("contractor");
      const filter: TContractFilter = {};
      if (clientId) filter.client = clientId;
      if (contractorId) filter.contractor = contractorId;
      return getContractList({ search: searchContractsValue, filter });
    }
  });

  const onClientContractorChange = (client: ID | undefined, contractor: ID | undefined) => {
    if (client && contractor) {
      contractsRequest();
    } else {
      setValue("contract", undefined);
    }
  };


  useEffect(() => {
    if (initialParams) {
      const client = initialParams.client?.id;
      const contractor = initialParams.contractor?.id;
      contractReset({ client, contractor });
      onClientContractorChange(client, contractor);
      clientParticipantsRequest();
      contractorParticipantsRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParams]);

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="client"
            title="Заказчик"
            optionKey={["name", "inn", "status"]}
            optionKeyLabels={["Название", "ИНН", "hidden"]}
            inputValue={searchClientParticipantsValue}
            setInputValue={setSearchClientParticipantsValue}
            control={contractControl}
            isLoading={clientParticipantsLoading}
            request={clientParticipantsRequest}
            response={clientParticipantsResponse}
            disabled={isLoading}
            clearable
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
            onChange={(newClient: ID | undefined) =>
              onClientContractorChange(newClient, contractWatch("contractor"))
            }
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="contractor"
            title="Исполнитель"
            optionKey={["name", "inn", "status"]}
            optionKeyLabels={["Название", "ИНН", "hidden"]}
            inputValue={searchContractorParticipantsValue}
            setInputValue={setSearchContractorParticipantsValue}
            control={contractControl}
            isLoading={contractorParticipantsLoading}
            request={contractorParticipantsRequest}
            response={contractorParticipantsResponse}
            disabled={isLoading}
            clearable
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
            onChange={(newContractor: ID | undefined) =>
              onClientContractorChange(contractWatch("client"), newContractor)
            }
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="contract"
            title="Договор"
            optionKey={["contract_number", "contract_date", "status"]}
            optionKeyLabels={["Номер", "Дата", "hidden"]}
            inputValue={searchContractsValue}
            setInputValue={setSearchContractsValue}
            control={control}
            isLoading={contractsLoading}
            request={contractsRequest}
            response={contractsResponse}
            disabled={isLoading || !contractWatch("client") || !contractWatch("contractor")}
            clearable
            errorText="Необходимо заполнить. Для заполнения выберете заказчика и исполнителя."
            errors={errors}
            isRequired
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <InputField
            name="invoice_number"
            title="Номер акта"
            control={control}
            errors={errors}
            errorText="Необходимо заполнить."
            isRequired={!noInvoiceNumber && !isDraft}
            isStared={!noInvoiceNumber}
            disabled={isLoading || noInvoiceNumber}
          />
        </div>
        <div className="col-sm-3 mt-4">
          <CheckBoxField
            name="noNumber"
            title="Нет номера"
            control={control}
            errors={errors}
            isChecked={noInvoiceNumber}
            disabled={isLoading}
            extraAction={() => {
              const newValue = watch("noNumber") as boolean | undefined;
              setValue("invoice_number", newValue ? "" : undefined);
            }}
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <InputField
            name="amount"
            title="Сумма акта в рублях"
            pattern={/^[\d ]*[,.]?\d*$/}
            control={control}
            errors={errors}
            errorText="Только числа"
            disabled={isLoading}
            isRequired={!isDraft}
            isStared
            isNumber
            validate={validateAmount}
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <DatePickerField
            name="invoice_date"
            title="Дата акта"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-6">
          <DatePickerField
            name="start_date"
            title="Дата начала отчетного периода по акту"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-sm-6">
          <DatePickerField
            name="end_date"
            title="Дата окончания отчетного периода по акту"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
          />
        </div>
      </div>
      <DropZoneField
        name="allocations_file"
        title="Загрузка разаллокации по изначальным договорам"
        exampleLink="/static/core/invoice_templates/allocation.xlsx"
        control={control}
        errors={errors}
        fileTypes={[".xlsx", ".xls", ".csv"]}
        uploadFunction={uploadAllocations}
        responsePrintField="file_path"
        // setAdditionalData={{
        //   fn: setAmountFromAllocationsFile,
        //   field: "amount"
        // }}
        oldFile={allocationsFileOld}
      >
        <p className="mb-0">
          В&nbsp;этом блоке Вам необходимо загрузить сводный файл с&nbsp;разбивкой общей суммы акта
          договора на&nbsp;отдельные суммы по&nbsp;изначальным договорам. В&nbsp;файле должна быть
          указана привязка к&nbsp;&quot;ID изначального договора/ДС&quot; в&nbsp;сервисе ADVmarker.
        </p>
      </DropZoneField>
      <DropZoneField
        name="creative_statistics_file"
        title="Загрузка статистики по креативам агентства"
        exampleLink="/static/core/invoice_templates/statisics.xlsx"
        control={control}
        errors={errors}
        fileTypes={[".xlsx", ".xls", ".csv"]}
        uploadFunction={uploadCreativeStatistic}
        responsePrintField="file_path"
        oldFile={statisticsFileOld}
        buttonInTitle={
          <Button
            type="button"
            outline
            color="primary"
            className="d-flex align-items-center gap-1"
            onClick={(e) => {
              e.preventDefault();
              toggleIsConnectStatToInvoiceModal();
            }}
          >
            <FaPlus />
            Привязать статистику к акту
          </Button>
        }
      >
        <p>
          В&nbsp;этом блоке Вам необходимо загрузить сводный файл со&nbsp;статистикой показов
          по&nbsp;креативам в&nbsp;рамках данного акта. В&nbsp;файле должна быть указана привязка
          креатива к&nbsp;&laquo;ID&nbsp;договора/ДС&raquo; в&nbsp;сервисе ADVmarker.
        </p>
      </DropZoneField>
      <ConnectStatToInvoiceModal
        isOpen={isConnectStatToInvoiceModalOpen}
        toggle={toggleIsConnectStatToInvoiceModal}
        setValue={(newValue) => setValue("connect_stat_to_invoice", newValue)}
        currentValue={(watch("connect_stat_to_invoice") as (string | number)[]) || []}
      />
      {buttons}
    </Form>
  );
};
