import axios from "axios";
import { Platform } from "models";
import { TPlatformFilter } from "types/TPlatformFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsPlatform extends TSearchParamsPropsNoFilter {
  filter?: TPlatformFilter;
}

export const getPlatformList = (props?: IPropsPlatform): Promise<APIListData<Platform>> => {
  return axios
    .get(
      props === undefined ? `/api/v1/platform/` : `/api/v1/platform/?${getSearchParams(props)}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        timeout: 10000
      }
    )
    .then((res) => {
      return res.data as Promise<APIListData<Platform>>;
    });
};
