import { TNotVisibleFields } from "utils/onErrorMutation";

export const notVisibleFieldsInvoice: TNotVisibleFields = [
  {
    name: "allocations_file",
    title: "Загрузка разаллокации по изначальным договорам"
  },
  {
    name: "creative_statistics_file",
    title: "Загрузка статистики по креативам агентства"
  }
];

export const amountMaxValue = 10000000000

export enum AmountValidateType {
  greaterThanMaxValue = 'greaterThanMaxValue'
}

export const amountErrorMessages = {
  [AmountValidateType.greaterThanMaxValue]: 'Значение суммы акта не должно быть более 10 000 000 000,00'
}
