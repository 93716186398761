import { useMemo } from "react";
import isArray from "lodash/isArray";
import { TPlatformFilter } from "../types/TPlatformFilter";
import { TCreativeFilter } from "../types/TCreativeFilter";
import { TContractFilter } from "../types/TContractFilter";
import { TParticipantFilter } from "../types/TParticipantFilter";
import { TStatisticsFilter } from "../types/TStatisticsFilter";

type Filter = TPlatformFilter | TParticipantFilter | TCreativeFilter | TContractFilter | TStatisticsFilter;
export const useFilterCount = (filter: Filter) => {
  const filterCount = useMemo(() => {
    let count = 0;
    Object.values(filter).forEach(value => {
      if (isArray(value) && !!value.length || !isArray(value) && !!value) count += 1;
    })
    return count;
  }, [filter])

  return filterCount;
};
